<template>
  <div class="rpk_avatar">
    <div class="rpk_avatar_around">
      <div
        v-if="avatarUrl && !uploadingImage"
        class="rpk_avatar_around_content"
        :style="`background-image: url(${avatarUrl});`"
      >
        <v-btn
          @click="delAvatar()"
          fab
          x-small
          color="white"
          class="rpk_avatar_around_content_del"
        >
          <v-icon small color="#c91616">
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
      <div v-if="!value && !uploadingImage" class="rpk_avatar_around_upload">
        <v-btn @click="uploadAvatar()" fab x-larg color="#e1e1e2">
          <v-icon color="#aaaeaf">
            mdi-camera
          </v-icon>
        </v-btn>
      </div>
      <div v-if="uploadingImage" class="rpk_avatar_around_upload">
        <v-progress-circular
          indeterminate
          color="#aaaeaf"
        ></v-progress-circular>
      </div>
    </div>
    <div style="display: none;" ref="file"></div>
  </div>
</template>

<script>
import Confirm from "@/components/Modals/Confirm";
import api from "@/utils/api";
import loadImage from "blueimp-load-image";
import imageCompression from "browser-image-compression";
const fileUrl = process.env.VUE_APP_FILE_URL;
export default {
  props: ["value"],
  data: () => ({
    avatarUrl: null,
    uploadingImage: false,
    origPath: null,
  }),
  created() {
    if (this.value) {
      this.origPath = this.value;
      this.loadAvatar(this.value);
    }
  },
  methods: {
    async uploadAvatar() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      const compressOptions = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1520,
        useWebWorker: true,
      };

      input.onchange = async (e) => {
        const _this = this;
        var files = await e.target.files;

        for (let key in files) {
          let file = files[key];

          if (file && file instanceof File) {
            loadImage.parseMetaData(file, function(data) {
              const options = {};
              // Get the correct orientation setting from the EXIF Data
              if (data.exif) {
                options.orientation = data.exif.get("Orientation");
              }

              // Load the image from disk and inject it into the DOM with the correct orientation
              loadImage(
                file,
                function(canvas) {
                  let trueCanvas = canvas;
                  if (canvas instanceof HTMLImageElement) {
                    const image = canvas;
                    trueCanvas = document.createElement("canvas");
                    trueCanvas.width = image.width;
                    trueCanvas.height = image.height;
                    trueCanvas.getContext("2d").drawImage(image, 0, 0);
                  }
                  trueCanvas.toBlob(async function(trueFile) {
                    let compressedFile = await imageCompression(
                      trueFile,
                      compressOptions
                    );

                    _this.uploadingImage = true;
                    let filePath = await api
                      .upload("upload", compressedFile)
                      .then((response) => {
                        if (response.status == 200) {
                          _this.uploadingImage = false;
                          return `${fileUrl}/${response.data.url}`;
                        }
                      });
                    _this.$emit("input", filePath);
                    _this.uploadingImage = false;
                  });
                },
                options
              );
            });
          }
        }
      };

      // input.onchange = async (e) => {
      //   var files = await e.target.files;

      //   for (let key in files) {
      //     let file = files[key];
      //     if (file && file instanceof File) {
      //       let compressedFile = await imageCompression(file, options);

      //       // let filePath = `avatar/${new Date().getTime()}_${key}.${/[^.]+$/.exec(file.name)}`
      //       // let ref = this.$storage.ref().child(filePath).put(compressedFile)
      //       // ref.on(`state_changed`, snapshot => {
      //       // this.uploadingImage = true
      //       // }, error => {
      //       // console.log(error)
      //       // }, () => {
      //       // this.$emit('input', filePath)
      //       // this.uploadingImage = false
      //       // })
      //       this.uploadingImage = true;
      //       let filePath = await api.upload('upload', compressedFile).then((response) => {
      //         if (response.status == 200) {
      //           this.uploadingImage = false;
      //           return `https://api.m4crm.ru/get-file/${response.data.url}`;
      //         }
      //       });
      //       this.$emit('input', filePath);
      //     }
      //   }

      //   this.uploadingImage = false;
      // };
      this.$refs.file.appendChild(input);
      input.click();
    },
    async delAvatar() {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление аватара`,
          text: `Действительно хотите удалить изображение?`,
        },
      });

      if (res) {
        if (this.value != this.origPath) {
          // await this.$delFbFile(this.value)
        }
        this.$emit("input", null);
      }
    },
    async loadAvatar(value) {
      if (value) {
        this.uploadingImage = true;
        // this.avatarUrl = await this.$getFbFileUrl(value)
        this.avatarUrl = value;
        let img = new Image();
        img.addEventListener(
          "load",
          () => {
            console.log("загрузилось");
            this.uploadingImage = false;
          },
          false
        );
        img.src = this.avatarUrl;
      }
    },
  },
  watch: {
    async value(newVal) {
      if (newVal) {
        this.loadAvatar(newVal);
      } else {
        this.avatarUrl = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rpk {
  &_avatar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &_around {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #e1e1e2;

      &_content {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-size: cover;
        background-position: center;

        &_del {
          position: absolute;
          bottom: 0.1vw;
          right: 0.1vw;
        }
      }

      &_upload {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
